import React from "react"

const ProgressCircle = ({ strokeDashoffset }) => {
  return (
    <svg
      width="57"
      height="58"
      viewBox="0 0 57 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.5" cy="29" r="25.5" stroke="#E4E6EA" strokeWidth="4" />
      <circle
        cx="28.5"
        cy="29"
        r="25.5"
        stroke="url(#progressbar_paint0_linear)"
        strokeWidth="6"
        strokeLinecap="round"
        id="gradient-circle"
        strokeDasharray="160"
        strokeDashoffset={strokeDashoffset}
      />
      <defs>
        <linearGradient
          id="progressbar_paint0_linear"
          x1="95"
          y1="63.5"
          x2="99.2771"
          y2="8.71784"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC3950" />
          <stop offset="0.1379" stopColor="#D65979" />
          <stop offset="0.2988" stopColor="#C17AA2" />
          <stop offset="0.4257" stopColor="#B38EBC" />
          <stop offset="0.502" stopColor="#AE96C5" />
          <stop offset="0.5748" stopColor="#B399BD" />
          <stop offset="0.6882" stopColor="#C2A0A7" />
          <stop offset="0.8282" stopColor="#D9AB83" />
          <stop offset="0.9879" stopColor="#F9BB51" />
          <stop offset="1" stopColor="#FCBC4D" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ProgressCircle
